import ContentList from '@components/blocks/ContentList';
import Grid from '@components/common/Grid';
import React from 'react';
import SEO from '@components/common/SEO';
import { graphql } from 'gatsby';

function Journal({ data, pageContext }) {
  const {
    seo,
    title,
    ACFGiornale: { articlesList },
  } = data.wpPage;
  const { language: lang, id } = pageContext;

  return (
    <>
      <SEO seo={seo} language={lang} />
      <Grid animKey={`page-${id}`} transition>
        <ContentList articles={articlesList} />
      </Grid>
    </>
  );
}

export const data = graphql`
  query Journal($id: String) {
    wpPage(id: { eq: $id }) {
      ACFGiornale {
        articlesList {
          ... on WpArticle {
            id
            uri
            title
            date
            featuredImage {
              node {
                altText
                mediaDetails {
                  width
                  height
                  sizes {
                    height
                    name
                    sourceUrl
                    width
                  }
                }
                mediaItemUrl
              }
            }
          }
        }
      }
      seo {
        ...seoFields
      }
    }
  }
`;

export default Journal;
